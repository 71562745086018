<template>
  <section>
    <h3 class="mb-8 text-h5">
      {{ $route.params.id_sancion ? "Edición" : "Creación" }} de reporte de
      sanción
    </h3>
    <h3 class="mb-8 text-h5" v-if="this.snapshot?.correlativo">
      Código: {{ this.snapshot?.correlativo }}
    </h3>

    <div v-if="$route.params.id_sancion" class="d-flex">
      <v-switch
        color="success"
        inactive-color="error"
        :ripple="false"
        inset
        v-model="form.id_estado"
        :label="form.id_estado ? 'Activa' : 'Inactiva'"
      />
    </div>

    <v-row class="mt-4">
      <v-col cols="12" sm="6" md="4">
        <vc-date-picker
          locale="es"
          mode="date"
          :model-config="modelConfig"
          :popover="config_calendar"
          :value="form.fecha_inicio"
          @input="updateFechaInicio"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              :value="form.fecha_inicio"
              class="border roundend focus:outline-none focus:border-blue-300"
              label="Fecha de inicio *"
              outlined
              prepend-inner-icon="mdi-calendar"
              readonly
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <vc-date-picker
          :min-date="minDate"
          locale="es"
          mode="date"
          :model-config="modelConfig"
          :popover="config_calendar"
          :value="form.fecha_fin"
          @input="updateFechaFin"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              :value="form.fecha_fin"
              class="border roundend focus:outline-none focus:border-blue-300"
              label="Fecha de fin *"
              outlined
              prepend-inner-icon="mdi-calendar"
              readonly
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          outlined
          v-model="form.id_tipo_documento"
          label="Tipo de documento *"
          :items="tipos_documentos"
          item-text="nombre"
          item-value="id"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          outlined
          v-model="form.numero_documento"
          label="Número de documento *"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" sm="12" md="8">
        <v-text-field
          outlined
          v-model="form.nombre_comercial"
          label="Nombre comercial *"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" sm="12" md="8">
        <v-textarea
          outlined
          v-model="form.detalle_sancion"
          label="Detalle de sanción *"
          :error-messages="detalleSancionError"
          @blur="$v.form.detalle_sancion.$touch"
          @input="$v.form.detalle_sancion.$touch"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" sm="12" md="8">
        <v-autocomplete
          v-model="institucionSeleccionada"
          outlined
          label="Institución *"
          :items="instituciones"
          item-text="nombre"
          item-value="id"
          clearable
          :disabled="!tieneRolActualizar"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" sm="12" md="8">
        <v-btn
          v-if="this.snapshot?.doc_sancion"
          outlined
          color="secondary"
          class="mb-4"
          @click="descargarDocumentoSancion"
        >
          Descargar documento actual
        </v-btn>

        <v-file-input
          accept="application/pdf"
          clearable
          label="Documento de sanción *"
          outlined
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          v-model="documento_sancion"
          :value="documento_sancion"
        />
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-btn
        outlined
        class="mr-4"
        color="secondary"
        minWidth="20%"
        @click="$router.push({ name: 'sanciones-proveedores-no-registrados' })"
      >
        Volver
      </v-btn>

      <v-btn
        solid
        color="secondary"
        min-width="25%"
        :disabled="$v.$invalid"
        @click="
          $route.params.id_sancion ? actualizarSancion() : guardarSancion()
        "
      >
        {{ $route.params.id_sancion ? "Actualizar sanción" : "Crear sanción" }}
      </v-btn>
    </v-row>

    <dialogoConfirmacion
      :show="confirmDialog"
      title="¿Desea realizar otra sanción?"
      btnConfirmar="Si"
      btnCancel="No"
      @close="$router.replace({ name: 'sanciones-proveedores-no-registrados' })"
      @confirm="resetForm"
    />
  </section>
</template>

<script>
  import moment from "moment";
  import { required, requiredIf, helpers } from "vuelidate/lib/validators";
  import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";

  const txtArea = helpers.regex(
    "txtArea",
    /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
  );

  export default {
    name: "adminSancionPreRegView",
    components: {
      dialogoConfirmacion,
    },
    data: () => ({
      form: {
        id_estado: true,
        fecha_inicio: null,
        fecha_fin: null,
        nombre_comercial: "",
        id_tipo_documento: null,
        numero_documento: "",
        detalle_sancion: "",
      },
      documento_sancion: null,
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      config_calendar: {
        visibility: "click",
      },
      tipos_documentos: [],
      instituciones: [],
      institucionSeleccionada: null,
      tieneRolActualizar: false,
      confirmDialog: false,
      snapshot: null,
    }),
    validations: {
      form: {
        fecha_inicio: {
          required,
        },
        fecha_fin: {
          required,
        },
        nombre_comercial: {
          required,
        },
        id_tipo_documento: {
          required,
        },
        numero_documento: {
          required,
        },
        detalle_sancion: {
          required,
          txtArea,
        },
      },
      documento_sancion: {
        required: requiredIf(function () {
          return !this.$route.params.id_sancion;
        }),
      },
    },
    computed: {
      minDate() {
        return moment().add(2, "days").format("YYYY-MM-DD");
      },
      detalleSancionError() {
        const errors = [];
        if (!this.$v.form.detalle_sancion.$dirty) return errors;
        !this.$v.form.detalle_sancion.required &&
          errors.push("El detalle de la sanción es requerido");
        !this.$v.form.detalle_sancion.txtArea &&
          errors.push("Caracteres especiales no permitidos");

        return errors;
      },
    },
    methods: {
      updateFechaInicio(value) {
        this.form.fecha_inicio = value;
      },
      updateFechaFin(value) {
        this.form.fecha_fin = value;
      },
      async guardarSancion() {
        this.$v.$touch();

        if (!this.$v.$invalid) {

          const formData = new FormData();
          formData.append("id_estado", this.form.id_estado);
          formData.append(
            "fecha_inicio",
            moment(this.form.fecha_inicio, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
          formData.append(
            "fecha_fin",
            moment(this.form.fecha_fin, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
          formData.append("nombre_comercial", this.form.nombre_comercial);
          formData.append("id_tipo_documento", this.form.id_tipo_documento);
          formData.append("numero_documento", this.form.numero_documento);
          formData.append("detalle_sancion", this.form.detalle_sancion);
          formData.append("documento", this.documento_sancion);
          formData.append("id_institucion", this.institucionSeleccionada);

          const response = await this.services.Sanciones.postSancionPreRegistro(
            formData
          );

          if (response.status === 201) {
            this.temporalAlert({
              show: true,
              message: "Sanción registrada correctamente",
              type: "success",
            });
            this.confirmDialog = true;
          }
        } else {
          this.temporalAlert({
            show: true,
            message: "Debe completar los campos obligatorios",
            type: "error",
          });
        }

      },
      async actualizarSancion() {
        this.$v.$touch();

        if (!this.$v.$invalid) {

          const formData = new FormData();
          formData.append("id_estado", this.form.id_estado);
          formData.append(
            "fecha_inicio",
            moment(this.form.fecha_inicio, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
          formData.append(
            "fecha_fin",
            moment(this.form.fecha_fin, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
          formData.append("nombre_comercial", this.form.nombre_comercial);
          formData.append("id_tipo_documento", this.form.id_tipo_documento);
          formData.append("numero_documento", this.form.numero_documento);
          formData.append("detalle_sancion", this.form.detalle_sancion);
          formData.append("documento", this.documento_sancion);
          formData.append("id_institucion", this.institucionSeleccionada);

          const response = await this.services.Sanciones.putSancionPrePregistro(
            this.$route.params.id_sancion,
            formData
          );

          if (response.status === 200) {
            this.temporalAlert({
              show: true,
              message: "Sanción actualizada correctamente",
              type: "success",
            });
            this.$router.replace({
              name: "sanciones-proveedores-no-registrados",
            });
          }
        } else {
          this.temporalAlert({
            show: true,
            message: "Debe completar los campos obligatorios",
            type: "error",
          });
        }

      },
      async getTiposDocumentos() {
        const response = await this.services.Documentos.getDocumentTypeList({
          id_categoria_documento: 1,
        });

        if (response.status === 200) {
          this.tipos_documentos = response.data;
        }
      },
      async resetForm() {
        this.form = {
          id_estado: true,
          fecha_inicio: null,
          fecha_fin: null,
          nombre_comercial: "",
          id_tipo_documento: null,
          numero_documento: "",
          detalle_sancion: "",
        };
        this.documento_sancion = null;
        this.confirmDialog = false;
      },
      async descargarDocumentoSancion() {

        const response =
          await this.services.PacProcesos.descargarDocumentoProceso({
            disk: "sanciones",
            ruta: this.snapshot.doc_sancion,
          });

        if (!response) {
          this.temporalAlert({
            show: true,
            message: "Ocurrió un error al descargar el documento",
            type: "error",
          });

          return;
        }

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const file = new File([blob], "documento_sancion", {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();

      },
      async cargarInstituciones() {
        const response = await this.services.Sanciones.getInstitucionUsuarioCatalogo();
        if (response?.status === 200) {
          this.instituciones = response.data?.instituciones;
        }
        
        if (this.$route.params.id_sancion) {
          const institucionEmpleado = this.snapshot?.id_institucion;
          const findInstitucion = this.instituciones.find(inst => inst.id === institucionEmpleado);
          this.institucionSeleccionada = findInstitucion?.id;
        } else {
          const institucionEmpleado = response.data?.idInstitucionEmpleado[0];
          const findInstitucion = this.instituciones.find(inst => inst.id === institucionEmpleado);
          this.institucionSeleccionada = findInstitucion?.id;
        }
      },
      verificarRol() {
        this.tieneRolActualizar = this.haveRole("ROLE_RUPES_ACTUALIZAR_SANCION_PROVEEDORES_REGISTRADOS");
      },
      async initAsyncOperations() {

      if (this.$route.params.id_sancion) {
        const response = await this.services.Sanciones.getSancionPreRegistro(
          this.$route.params.id_sancion
        );

        if (response.status === 200) {
          this.form = {
            id_estado: response.data.id_estado == 1 ? true : false,
            fecha_inicio: moment(response.data.fecha_inicio).format(
              "DD/MM/YYYY"
            ),
            fecha_fin: moment(response.data.fecha_fin).format("DD/MM/YYYY"),
            nombre_comercial:
              response.data.PreRegistroProveedor.nombre_comercial,
            id_tipo_documento:
              response.data.PreRegistroProveedor.id_tipo_documento,
            numero_documento:
              response.data.PreRegistroProveedor.numero_documento,
            detalle_sancion: response.data.detalle_sancion,
          };
          this.snapshot = response.data;
        }
      }
    },
    },
    created(){
      this.cargarInstituciones();
      this.verificarRol();
      this.initAsyncOperations();
      this.getTiposDocumentos();
    },
  };
</script>
